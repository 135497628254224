exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-contact-info-tsx": () => import("./../../../src/pages/contact-info.tsx" /* webpackChunkName: "component---src-pages-contact-info-tsx" */),
  "component---src-pages-events-varttina-varipata-en-tsx": () => import("./../../../src/pages/events/varttina-varipata/en.tsx" /* webpackChunkName: "component---src-pages-events-varttina-varipata-en-tsx" */),
  "component---src-pages-events-varttina-varipata-index-tsx": () => import("./../../../src/pages/events/varttina-varipata/index.tsx" /* webpackChunkName: "component---src-pages-events-varttina-varipata-index-tsx" */),
  "component---src-pages-events-varttina-varipata-registered-tsx": () => import("./../../../src/pages/events/varttina-varipata/registered.tsx" /* webpackChunkName: "component---src-pages-events-varttina-varipata-registered-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

